<template>
  <div
    class="container-fluid ingredientes text-center text-sm-left pr-0 pl-0 pl-sm-3 pr-sm-3 bg-white"
  >
    <div class="row">
      <div class="col-12 col-sm-2 mt-sm-2">
        <h3 class="text-center">
          <i class="fas fa-receipt"></i>
          Relatórios
        </h3>
      </div>
      <div class="col-12 col-sm-4">
        <label>Período</label>
        <v-date-picker v-model="dateRange" is-range class="text-center">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input
                :value="`${inputValue.start} - ${inputValue.end}`"
                v-on="inputEvents.start"
                class="form-control"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="col-12 col-sm-4">
        <label>Empresas</label>
        <v-select
          style="max-width: 500px; display: block"
          multiple
          v-model="empresaSelecionada"
          :options="empresas"
          label="nome"
        />
      </div>
    </div>
    <hr class="mb-2" />
    <div class="row">
      <div class="col-2 menuLateral">
        <router-link
          to="/relatorios/consumo-estoque/"
          active-class="selecionado"
        >
          <b-icon-calculator />
          Consumo de Estoque
        </router-link>
        <router-link to="/relatorios/pedidos/" active-class="selecionado">
          <b-icon-cart />
          Gestão de Pedidos
        </router-link>
        <router-link
          to="/relatorios/produtos-vendidos/"
          active-class="selecionado"
        >
          <b-icon-arrow-down />
          Produtos Vendidos
        </router-link>
        <router-link
          to="/relatorios/acessos-usuario/"
          active-class="selecionado"
        >
          <b-icon-person-lines-fill />
          Acessos de Usuário
        </router-link>
        <!-- <router-link
          to="/relatorios/movimentacao-financeira/"
          active-class="selecionado"
        >
          <b-icon-cash-coin />
          Mov. Financeira
        </router-link> -->
      </div>
      <div class="col-10 col-sm-10 bg-white">
        <router-view
          :empresaSelecionada="empresaSelecionada"
          :dateRange="dateRange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      empresaSelecionada: [],
      ingredientes: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/ingredientes.scss";
</style>
